import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/gcpvn/gcp.vn/src/components/static-layout.js";
import SEO from "../components/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Điều Khoản Dịch Vụ" mdxType="SEO" />
    <h1>{`ĐIỀU KHOẢN DỊCH VỤ GCP`}</h1>
    <h2>{`Điều 1: Giải thích từ ngữ`}</h2>
    <p><strong parentName="p">{`GCP:`}</strong>{` là phần mềm GCP, chữ GCP viết tắt từ GCParty. Phần mềm GCP do công ty GCP Company Limited là chủ quản.`}</p>
    <p><strong parentName="p">{`Thỏa Thuận:`}</strong>{` là thỏa thuận sử dụng phần mềm GCP, cùng với tất cả các bản sửa đổi, bổ sung, cập nhật.`}</p>
    <p><strong parentName="p">{`Thông Tin Cá Nhân:`}</strong>{` là những thông tin góp phần định danh một cá nhân thông tin gắn liền với việc xác định danh tính, nhân thân của cá nhân bao gồm tên, tuổi, địa chỉ, số chứng minh nhân dân, số điện thoại, địa chỉ thư điện tử, tài khoản ngân hàng của Khách Hàng và một số thông tin khác theo quy định của pháp luật.`}</p>
    <p><strong parentName="p">{`Khách Hàng:`}</strong>{` là chủ tài khoản sở hữu hợp pháp tài khoản GCP.`}</p>
    <p><strong parentName="p">{`Sở Hữu Trí Tuệ:`}</strong>{` là những sáng chế, cải tiến, thiết kế, quy trình, công thức, phương pháp, cơ sở dữ liệu, thông tin, bản vẽ, mã, chương trình máy tính, tác phẩm có bản quyền (hiện tại và tương lai), thiết kế mạch tích hợp bán dẫn, thương hiệu, nhãn hiệu (dù đã đăng ký hay chưa đăng ký) tên thương mại và (thiết kế) bao bì thương phẩm.`}</p>
    <h2>{`Điều 2: Nội dung dịch vụ`}</h2>
    <p><strong parentName="p">{`Quản lý phòng máy:`}</strong>{` Phần mềm GCP đáp ứng đầy đủ các nhu cầu cần thiết cho việc quản lý đại lý phòng máy Internet công cộng như bao gồm nhưng không giới hạn các tính năng như: `}<em parentName="p">{`Quản lý máy trạm; Quản lý khách vãng lai/ hội viên/ nhóm hội viên; Tính tiền (bao gồm dịch vụ ăn uống và thời gian phí) và ghi nhận chi tiết tất cả giao dịch phát sinh; Xem các ứng dụng từ xa; Nhật ký hệ thống; Lập báo cáo doanh thu.`}</em>{`
Ngoài các tính năng cơ bản trên, GCP còn tích hợp sẵn công cụ quản lý cập nhật ứng dụng.`}</p>
    <p><strong parentName="p">{`GCP`}</strong>{` thường xuyên tổ chức các chương trình khuyến mãi hấp dẫn dành cho các phòng máy và các chương trình chăm sóc các phòng máy có gắn bó cùng sản phẩm GCP.`}</p>
    <h2>{`Điều 3: Tải và hướng dẫn cài đặt, sử dụng sản phẩm`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Người Sử Dụng tự chịu trách nhiệm về năng lực hành vi trong việc cài đặt và sử dụng sản phẩm`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Hướng dẫn tải được thể hiện chi tiết tại `}<a parentName="p" {...{
            "href": "https://gcp.vn"
          }}>{`https://gcp.vn`}</a></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Khách Hàng có trách nhiệm tự bảo mật thông tin tài khoản của mình, nếu những thông tin trên bị tiết lộ dưới bất kỳ hình thức nào thì Khách Hàng phải chấp nhận những rủi ro phát sinh. GCP sẽ căn cứ vào những thông tin hiện có trong tài khoản để làm căn cứ quyết định chủ sở hữu tài khoản nếu có tranh chấp và GCP sẽ không chịu trách nhiệm về mọi tổn thất phát sinh cho Người Sử Dụng.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Nếu Khách Hàng biết hoặc nghi ngờ rằng mật khẩu hoặc tài khoản của mình đã bị xâm nhập, hãy báo ngay cho Hotline GCP`}</p>
      </li>
    </ol>
    <h2>{`Điều 4: Chấp nhận điều khoản và cập nhật.`}</h2>
    <p>{`Khách hàng cần đọc, đồng ý và chấp nhận tất cả các quy định trong Bản thỏa thuận này trước khi sử dụng GCP. Khi đã sử dụng GCP thì có nghĩa là Người Sử Dụng đã hoàn toàn tự nguyện đồng ý, chấp nhận cũng như tuân thủ tất cả các quy định trong Bản Thỏa Thuận này và/hoặc các quy định, chính sách hoặc/và điều khoản liên quan khác trong quá trình sử dụng GCP.`}</p>
    <p>{`Để đáp ứng nhu cầu của Người Sử Dụng, GCP không ngừng hoàn thiện và phát triển các sản phẩm, dịch vụ, vì vậy các điều khoản Thỏa Thuận sử dụng dịch vụ này có thể được cập nhật, chỉnh sửa bất cứ lúc nào mà không cần phải thông báo trước tới Người Sử Dụng. GCP sẽ công bố rõ trên Website, diễn đàn về những thay đổi, bổ sung đó.
Thỏa Thuận sử dụng này được áp dụng cho các sản phẩm/dịch vụ GCP bao gồm nhưng không giới hạn các sản phẩm GCP Quản lý dịch vụ, GCP Quickview, GCP PUBG Store...`}</p>
    <h2>{`Điều 5: Các hành vi cấm`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Vi phạm Thỏa Thuận này và/hoặc tất cả các chính sách/ quy định khác mà Người Sử Dụng đã đồng ý với GCP.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Cản trở trái pháp luật, gây rối, phá hoại hệ thống máy chủ. Không được có bất kỳ hành vi nào nhằm đăng nhập trái phép hoặc tìm cách đăng nhập trái phép hoặc gây thiệt hại cho hệ thống máy chủ.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Sử dụng, xâm nhập trái phép vào tài khoản của người khác để sử dụng sản phẩm/dịch vụ.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Hành động có tính chất làm mất uy tín, phỉ bang, bôi nhọ, đe dọa và quấy rối, xâm hại đến quyền và lợi ích hợp pháp của tổ chức, cá nhân.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Cung cấp thông tin sai sự thật, gây nhầm lẫn, sử dụng từ ngũ tục tĩu không phù hợp với thuần phong mỹ tục đối với sản phẩm tại các diễn đàn về sản phẩm.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Tạo đường dẫn trái phép đối với tên miền của tổ chức, cá nhân. Tạo, cài đặt, phát tán các phần mềm độc hại, vi rút máy tính; xâm nhập trái phép, chiếm quyền điều khiển hệ thống thông tin, tạo lập công cụ tấn công trên Internet.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Phát tán virus, Trojan, worms hoặc các chương trình máy tính có hại gây trở ngại, gian lận hoặc ảnh hưởng tới hệ thống, dữ liệu hoặc thông tin của khách hàng khác và/hoặc của GCP. Tuyệt đối không sử dụng bất kỳ chương trình, công cụ hay hình thức nào khác để can thiệp vào sản phẩm GCP.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Sử dụng bất kỳ các loại robot, spider, các thiết bị tự động khác hoặc thực hiện thủ công để giám sát hoặc sao chép website, các ứng dụng, giải pháp của GCP mà không được cho phép. Nghiêm cấm việc phát tán, truyền bá hay cổ vũ cho bất kỳ hoạt động nào nhằm can thiệp, phá hoại hay xâm nhập vào dữ liệu của sản phẩm cung cấp hoặc hệ thống máy chủ.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Không có những hành vi, thái độ làm tổn hại đến uy tín các sản phẩm của GCP, hoặc GCP, dưới bất kỳ hình thức hoặc phương thức nào.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Từ chối hợp tác điều tra, hoặc không cung cấp thông tin theo yêu cầu.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Thực hiện hành động làm mất kết nối nhằm xâm hại đến quyền và lợi ích hợp pháp của tổ chức, cá nhân hoặc thực hiện hành vi vi phạm pháp luật.`}</p>
      </li>
    </ol>
    <h2>{`Điều 6: Giới hạn trách nhiệm`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`GCP sẽ không chịu trách nhiệm đối với bất cứ vấn đề gì về hệ thống trong quá trình bạn sử dụng sản phẩm`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Nếu phát sinh rủi ro, thiệt hại trong trường hợp bất khả kháng bao gồm nhưng không giới hạn như chập điện, hư hỏng phần cứng, phần mềm, sự cố đường truyền internet hoặc do thiên tai .v.v. người dùng phải chấp nhận những rủi ro, thiệt hại nếu có. GCP cam kết sẽ nỗ lực giảm thiểu những rủi ro, thiệt hại phát sinh tuy nhiên GCP sẽ không chịu bất cứ trách nhiệm nào phát sinh trong các trường hợp này.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Khách hàng tự chịu trách nhiệm về các hành động bao gồm nhưng không giới hạn các hành động như tự cài đặt các tool khác không do GCP cung cấp dẫn đến thay đổi các thông số, tính năng của trò chơi. GCP được loại trừ trách nhiệm này. Cấm tuyệt đối Khách Hàng cài đặt các tool tác động trực tiếp lên sản phẩm dẫn đến nguy hại tới sản phẩm GCP.`}</p>
      </li>
    </ol>
    <h2>{`Điều 7: Thông tin và bảo mật`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`GCP có thể sử dụng Thông Tin Cá Nhân của Khách Hàng cho mục đích chăm sóc khách hàng, khảo sát về các sản phẩm và dịch vụ của GCP đồng thời phát triển các dịch vụ mới và cải thiện các dịch vụ hiện có nhằm đáp ứng mong muốn và sở thích của Khách Hàng.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`GCP có thể sử dụng dữ liệu để tùy biến và cải tiến nhằm phục vụ Khách Hàng. tốt hơn. GCP không sử dụng thông tin của Khách Hàng vào mục đích bất hợp pháp. GCP được quyền cung cấp thông tin của Khách Hàng cho Bên Thứ Ba trong các trường hợp nhưng không giới hạn:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Được Khách Hàng chấp thuận.`}</li>
          <li parentName="ul">{`Dịch vụ của GCP cung cấp yêu cầu sự tương tác với Bên Thứ Ba hoặc do Bên Thứ Ba cung cấp;`}</li>
          <li parentName="ul">{`Theo các quy định của pháp luật;`}</li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`GCP luôn cố gắng đáp ứng những tiêu chuẩn về dữ liệu trong chính sách bảo vệ riêng tư, tuy nhiên GCP không bị buộc phải bảo đảm những tiêu chuẩn đó. Có thể có những nhân tố vượt ngoài tầm kiểm soát của GCP dẫn đến việc dữ liệu bị tiết lộ. Vì vậy, GCP không chịu trách nhiệm bảo đảm dữ liệu luôn được duy trì ở tình trạng hoàn hảo hoặc không bị tiết lộ.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Mọi Thông Tin Cá Nhân của Khách Hàng sẽ được GCP bảo mật, không tiết lộ ra ngoài. GCP không bán hay trao đổi những thông tin này với bất kỳ một Bên Thứ Ba nào khác, trừ trường hợp được quy định trong Thỏa Thuận này.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Trong quá trình sử dụng sản phẩm, Khách Hàng đồng ý nhận tất cả thông báo từ GCP liên quan tới sản phẩm và dịch vụ qua thư điện tử, thư bưu chính hoặc điện thoại của Khách Hàng. Trong trường hợp Khách Hàng đăng ký sử dụng sản phẩm, dịch vụ do Bên Thứ Ba cung cấp thì những thông tin của Khách Hàng sẽ được chia sẻ với Bên Thứ Ba. Khách Hàng cũng đồng ý nhận tất cả thông báo từ Bên Thứ Ba liên quan tới sản phẩm và dịch vụ qua thư điện tử, thư bưu chính hoặc điện thoại của Khách Hàng.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`GCP có thể dùng thông tin tài khoản của bạn để thực hiện các hoạt động nghiên cứu, xúc tiến thương mại.`}</p>
      </li>
    </ol>
    <h2>{`Điều 8: Quyền sở hữu trí tuệ`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Tất cả quyền sở hữu trí tuệ tồn tại trong GCP của GCP đều thuộc về GCP hoặc được cấp phép hợp pháp cho GCP sử dụng, theo đó, tất cả các quyền hợp pháp đều được đảm bảo. Trừ khi được sự đồng ý của GCP bằng văn bản, Khách Hàng không được phép sử dụng, sao chép, xuất bản, tái sản xuất, truyền hoặc phân phát bằng bất cứ hình thức nào, bất cứ thành phần nào các quyền Sở Hữu Trí Tuệ đối với sản phẩm.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`GCP có toàn quyền, bao gồm nhưng không giới hạn trong các quyền về sở hữu công nghiệp, thương hiệu, bí mật kinh doanh và các quyền sở hữu khác trong tất cả các sản phẩm của GCP. Việc sử dụng quyền và sở hữu của GCP cần phải được GCP cho phép trước bằng văn bản. Ngoài việc được cấp phép bằng văn bản, GCP không cấp phép dưới bất kỳ hình thức nào khác cho dù đó là hình thức công bố hay hàm ý để bạn thực hiện các quyền trên. Và do vậy, Người Sử Dụng không có quyền sử dụng sản phẩm của GCP vào mục đích thương mại mà không có sự cho phép bằng văn bản của GCP trước đó.`}</p>
      </li>
    </ol>
    <h2>{`Điều 9: Giải quyết tranh chấp`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Bất kỳ tranh chấp phát sinh trong quá trình sử dụng của sử dụng sản phẩm GCP sẽ được giải quyết theo pháp luật hiện hành của nước Cộng hòa xã hội chủ nghĩa Việt Nam.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Nếu có bất kỳ khiếu nại nào phát sinh trong quá sử dụng sản phẩm phải được gửi đến GCP ngay sau khi xảy ra sự kiện phát sinh khiếu nại:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Địa chỉ liên lạc: `}<strong parentName="li">{`Bộ phận hỗ trợ khách hàng - Phần mềm GCP`}</strong></li>
          <li parentName="ul">{`Địa chỉ: `}<strong parentName="li">{`Số 145 đường Khương Trung, Phường Khương Trung, Quận Thanh Xuân, Hà Nội`}</strong></li>
          <li parentName="ul">{`Hotline: `}<strong parentName="li">{`024 7777 7868`}</strong></li>
          <li parentName="ul">{`Email: `}<strong parentName="li"><a parentName="strong" {...{
                "href": "mailto:contact@gcp.vn"
              }}>{`contact@gcp.vn`}</a></strong></li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`GCP sẽ căn cứ từng trường hợp cụ thể để có phương án giải quyết cho phù hợp. Khi thực hiện quyền khiếu nại, người khiếu nại có nghĩa vụ cung cấp các giấy tờ, bằng chứng, căn cứ có liên quan đến việc khiếu nại và phải chịu trách nhiệm về nội dung khiếu nại, giấy tờ, bằng chứng, căn cứ do mình cung cấp theo quy định pháp luật.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`GCP chỉ hỗ trợ, giải quyết khiếu nại, của Khách Hàng trong trường hợp bạn đã ghi đầy đủ, trung thực và chính xác thông tin khi đăng ký tài khoản.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Đối với tranh chấp giữa Khách Hàng với bên thức 3 liên quan tới sản phẩm GCP, có thể GCP sẽ gửi thông tin liên hệ cho các đối tượng tranh chấp để các bên tự giải quyết hoặc GCP sẽ căn cứ vào tình hình thực tế để giải quyết. Theo đó, GCP sẽ bảo vệ quyền lợi tối đa có thể cho Khách Hàng sử dụng sản phẩm hợp pháp và chính đáng.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Khách hàng đồng ý bảo vệ, bồi hoàn và loại trừ GCP khỏi những nghĩa vụ pháp lý, tố tụng, tổn thất, chi phí bao gồm nhưng không giới hạn án phí, chi phí luật sư, chuyên gia tư vấn có liên quan đến việc giải quyết hoặc phát sinh từ sự vi phạm của Khách Hàng trong quá trình sử dụng sản phẩm.`}</p>
      </li>
    </ol>
    <h2>{`Điều 10: Quyền lợi và trách nhiệm Khách hàng`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Được cài đặt, sử dụng sản phẩm GCP.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Được thực hiện các quyền khác theo quy định pháp luật.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Khách Hàng phải có đăng ký kinh doanh dịch vụ internet và/hoặc kinh doanh trò chơi điện tử hoặc game online hợp pháp; Đảm bảo và hoàn toàn chịu trách nhiệm về hoạt động kinh doanh cũng như trách nhiệm với khách hàng sử dụng dịch vụ internet theo quy định hiện hành của pháp luật;`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Khách Hàng phải đăng ký đầy đủ các thông tin trung thực, chính xác. Nếu có sự thay đổi, bổ sung về thông tin, bạn cập nhật ngay cho GCP. Khách Hàng đảm bảo rằng, thông tin hiện trạng của mình là mới nhất, đầy đủ, trung thực và chính xác và Khách Hàng phải chịu trách nhiệm toàn bộ các thông tin bạn cung cấp.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Khách Hàng phải đăng ký số lượng máy tính sử dụng với GCP để được cấp mã số cài đặt. Bạn chỉ được cài đặt và/hoặc sử dụng Phần Mềm GCP để quản lý phòng máy tính mà bạn đã đăng ký với GCP.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Trong trường hợp có sự thay đổi quyền sở hữu hoặc quyền sử dụng cơ sở kinh doanh hoặc phòng máy tính có máy tính cài đặt và/hoặc sử dụng Phần Mềm GCP thì Khách Hàng phải thông báo cho GCP về sự thay đổi đó và bên nhận chuyển nhượng hoặc chuyển giao hoặc chủ sở hữu mới đương nhiên được coi là kế thừa nghĩa vụ tuân thủ các quy định Thỏa Thuận này nếu tiếp tục cài đặt và/hoặc sử dụng Phần Mềm GCP.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Khách Hàng có trách nhiệm tuân thủ các điều cấm trong thỏa thuận này. Có trách nhiệm bảo mật thông tin tài khoản, nếu những thông tin trên bị tiết lộ dưới bất kỳ hình thức nào thì Khách Hàng phải chấp nhận những rủi ro phát sinh.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Khách Hàng đồng ý sẽ thông báo ngay cho GCP về bất kỳ trường hợp nào sử dụng trái phép tài khoản và mật khẩu của bạn hoặc bất kỳ các hành động phá vỡ hệ thống bảo mật nào.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Khách Hàng đồng ý sẽ thông báo ngay cho GCP về bất kỳ trường hợp nào sử dụng tool của bên thứ 3 tác động lên GCP. Mọi hành vi tác động gây ra hoạt động không bình thường của sản phẩm sẽ bị cấm.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Khi phát hiện ra lỗi của sản phẩm , các vấn đề gây ảnh hưởng tới hoạt động bình thường của GCP, Khách Hàng hãy thông báo cho GCP qua đường dây nóng: `}<strong parentName="p">{`024 7777 7868`}</strong>{` hoặc gửi yêu cầu hỗ trợ qua kênh email hỗ trợ trực tuyến `}<strong parentName="p"><a parentName="strong" {...{
              "href": "mailto:contact@gcp.vn"
            }}>{`contact@gcp.vn`}</a></strong>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Khách Hàng cam kết thực hiện trách nhiệm bảo đảm sử dụng hợp pháp nội dung thông tin số đưa lên đăng tải trên hệ thống mạng Internet và mạng viễn thông.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Khách Hàng phải tuân thủ các quy định sử dụng thỏa thuận này, nếu vi phạm một hoặc nhiều hành vi, tùy thuộc vào mức độ vi phạm GCP sẽ khóa tài khoản vĩnh viễn, tước bỏ mọi quyền lợi của bạn đối các sản phẩm của GCP và sẽ yêu cầu cơ quan chức năng truy tố Khách Hàng trước pháp luật nếu cần thiết.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Thực hiện trách nhiệm khác theo quy định pháp luật.`}</p>
      </li>
    </ol>
    <h2>{`Điều 11: Quyền và trách nhiệm GCP`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Mọi vi phạm của Người Sử Dụng trong quá trình sử dụng sản phẩm GCP, GCP có quyền tước bỏ mọi quyền lợi của Người Sử Dụng đối với việc sử dụng các sản phẩm cũng như sẽ yêu cầu cơ quan chức năng truy tố Khách Hàng trước pháp luật nếu cần thiết.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`GCP có quyền hạn chế, tạm ngừng, chấm dứt, hoặc thực hiện các hành vi khác liên quan đến việc cài đặt và/hoặc sử dụng toàn bộ hoặc bất kỳ phần nào của Phần Mềm GCP mà không cần phải gửi thông báo trước cho bạn, và GCP cũng sẽ không phải chịu bất cứ trách nhiệm nào khi thực hiện các hành vi đó.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Khi phát hiện những vi phạm như sử dụng cheats, hacks, hoặc những lỗi khác, GCP có quyền sử dụng những thông tin mà Khách Hàng cung cấp khi đăng ký tài khoản để chuyển cho Cơ quan chức năng giải quyết theo quy định của pháp luật.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Có trách nhiệm hỗ trợ Khách Hàng trong quá trình sử dụng sản phẩm của GCP.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Nhận và giải quyết khiếu nại của Khách Hàng các trường hợp phát sinh trong quá trình sử dụng GCP, tuy nhiên GCP chỉ hỗ trợ, nhận và giải quyết đối với tài khoản đăng ký đầy đủ thông tin, trung thực và chính xác.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Có trách nhiệm bảo mật thông tin cá nhân của Khách Hàng, GCP không bán hoặc trao đổi những thông tin này với Bên Thứ Ba, trừ trường hợp theo quy định pháp luật hoặc được chủ tài khoản chấp nhận.`}</p>
      </li>
    </ol>
    <h2>{`Điều 12: Điều khoản thi hành.`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Các điều khoản quy định tại Quy định sử dụng này có thể được cập nhật, chỉnh sửa bất cứ lúc nào mà không cần phải thông báo trước tới Khách Hàng. GCP sẽ công bố rõ trên Website, diễn đàn về những thay đổi, bổ sung đó.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Trong trường hợp một hoặc một số điều khoản Quy định sử dụng này xung đột với các quy định của luật pháp và bị Tòa án tuyên là vô hiệu, điều khoản đó sẽ được chỉnh sửa cho phù hợp với quy định pháp luật hiện hành, và phần còn lại của Quy chế sử dụng vẫn giữ nguyên giá trị.`}</p>
      </li>
    </ol>
    <p><em parentName="p">{`Điều khoản dịch vụ có giá trị từ ngày 18 tháng 01 năm 2017.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      